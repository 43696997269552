import React from 'react';
import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { NavSidebar } from './NavSidebar';

const Container = styled.div`
  background-color: var(--white);
  height: 100vh;
  height: 100dvh; //iOS safari
  display: flex;
  flex-direction: row;

  &.creamBg {
    background-color: var(--light-surface);
  }
`;

const MainContent = styled.div`
  flex: 1;
  padding: 1.25rem;
  margin-left: var(--nav-sidebar-width);
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

interface PageContainerProps {
  title: string;
  children: React.ReactNode;
  onBack?: () => void;
  centered?: boolean;
  // TODO: consider updating 'cream' name when the color tokens are updated
  backgroundColor?: 'white' | 'cream';
}

const PageContainer: React.FC<PageContainerProps> = ({ title, children, backgroundColor }) => {
  return (
    <Container className={backgroundColor === 'cream' ? 'creamBg' : ''}>
      <NavSidebar />
      <MainContent>
        <TitleContainer>
          <Typography styledAs="h5" as="h1">{title}</Typography>
        </TitleContainer>
        {children}
      </MainContent>
    </Container>
  );
};

export default PageContainer;
