import React, { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { FileInfoResponse, apiHost, client } from '../../api';
import { taskFileQueryKey } from '../../queries';
import { useQuery } from '@tanstack/react-query';
import { TaskContext } from './contexts';
import downloadFile from 'design-system/src/assets/icons/downloadFile.svg';

const FileDisplay = styled.div<{ $solid?: boolean; $active?: boolean }>`
  display: inline-block;
  border-radius: 6px;
  border: 1px solid var(--border-gray);
  background: var(--white);
  width: 150px;
  margin-right: 0.5rem;
`;

const AttachedFileDisplay = styled(FileDisplay)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  margin-right: 0;
`;

FileDisplay.defaultProps = {
  $solid: false,
  $active: false,
};

const UploadBox = styled.div`
  display: flex;
  height: 44px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  cursor: pointer;
`;

const FileDownloadLinkContainer = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 6px;
  flex-shrink: 0;
  display: flex;
  img {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
  }
`;

const FileDownloadLink: React.FC<{ fileId: string; taskId: string }> = ({ fileId, taskId }) => {
  return (
    <a href={`${apiHost}/tasks/${taskId}/file/${fileId}/download`} download>
      <FileDownloadLinkContainer>
        <img src={downloadFile} alt="Download" />
      </FileDownloadLinkContainer>
    </a>
  );
};

const formatFileName = (filename: string) => {
  if (filename.length > 14) {
    return `${filename.slice(0, 10)}...${filename.slice(-4)}`;
  }
  return filename;
};

export const FileUploader: React.FC = () => {
  const [hasError, setHasError] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const { refetchTask, taskId, setUpdating } = useContext(TaskContext);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setUpdating(true);
    setUploading(true);
    await Promise.all(
      acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
          await client.post(`tasks/${taskId}/file`, formData);
        } catch (error) {
          console.error('Error uploading file:', error);
          setHasError(true);
        }
      }),
    );
    await refetchTask();
    setUpdating(false);
    setUploading(false);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  let buttonLabel: string;
  if (isDragActive) {
    buttonLabel = 'Drop files here';
  } else if (uploading) {
    buttonLabel = 'Uploading...';
  } else {
    buttonLabel = hasError ? 'Error Uploading File' : 'Attach file';
  }

  return (
    <FileDisplay {...getRootProps({ $active: isDragActive })}>
      <input {...getInputProps()} />
      <UploadBox>{buttonLabel}</UploadBox>
    </FileDisplay>
  );
};

const FileInfo = styled.div`
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 114.286%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
`;

const FileName = styled.div`
  color: var(--black);
  font-weight: 500;
`;
interface UploadedFileProps {
  fileId: string;
  onFileDelete?: () => void;
}

export const UploadedFile: React.FC<UploadedFileProps> = ({ fileId, onFileDelete }) => {
  const { taskId } = useContext(TaskContext);

  const query = useQuery<FileInfoResponse>({
    queryKey: taskFileQueryKey(fileId),
    queryFn: async () => {
      const res = await client.get(`/tasks/${taskId}/file/${fileId}`);
      return res.data;
    },
  });

  const filename = query.data?.filename || 'Loading...';

  return (
    <FileDisplay $solid>
      <FileInfo>
        <FileName title={filename}>{formatFileName(filename)}</FileName>
      </FileInfo>
      {onFileDelete && <UploadBox onClick={() => onFileDelete()}>Remove</UploadBox>}
    </FileDisplay>
  );
};

export const AttachedFile: React.FC<{ fileId: string }> = ({ fileId }) => {
  const { taskId } = useContext(TaskContext);

  const query = useQuery<FileInfoResponse>({
    queryKey: taskFileQueryKey(fileId),
    queryFn: async () => {
      const res = await client.get(`/tasks/${taskId}/file/${fileId}`);
      return res.data;
    },
  });

  const filename = query.data?.filename || 'Loading...';

  return (
    <AttachedFileDisplay>
      <FileDownloadLink fileId={fileId} taskId={taskId} />
      <FileInfo>
        <FileName title={filename}>{formatFileName(filename)}</FileName>
      </FileInfo>
    </AttachedFileDisplay>
  );
};
