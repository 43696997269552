import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { client, url } from '../../api';
import { PDFViewer } from '../PDFViewer';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import { BodyContainer, TopNavAndContent, ContentOuterWrapper, CenteredContent } from '../tableItemDetailViewComponents/Containers';
import { Form, FormOverlay, FormOverlayLoadingSpinner, ResponseInput, ButtonRow } from '../tableItemDetailViewComponents/FormComponents';
import React, { useContext, useState } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import styled from 'styled-components';
import { AppealReviewContext } from './contexts';
import { Button } from '../Button';
import moment from 'moment';
import { Typography } from 'design-system/src/components/Typography/Typography';
import warning from 'design-system/src/assets/icons/warning.svg';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import toast from "react-hot-toast";
import { Toast } from "design-system/src/components/Toast/Toast";

// This formats moment to use a single letter for the time unit
// TODO: move to a helper file
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1M',
    MM: '%dM',
    y: '1Y',
    yy: '%dY'
  }
})

const FormContainer = styled.div`
  margin: 1.5rem 0;
`;

const FormButtonContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
`;

const ReviewButtonContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const SmallButton = styled(Button)`
  display: flex;
  height: 2rem;
  padding: 0px 0.75rem;
  align-items: center;
  border-radius: var(--border-radius-medium);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.15px;
`;

const ReviewButton = styled(SmallButton)`
  color: var(--black);
  background-color: transparent;
  gap: 0.25rem;
  border: 1px solid var(--border-gray);
  transition: background-color 0.2s ease-out;
`;

const AcceptButton = styled(ReviewButton)`
  &:hover {
    background-color: var(--light-border-gray);
    border-color: var(--light-border-gray);
  }
`;

const RejectButton = styled(ReviewButton) <{ $selected: boolean }>`
  display: flex;
  gap: 0.5rem;

  > img {
    width: 1rem;
    height: 1rem; 
  }

  &:hover {
    background-color: var(--light-yellow);
    border-color: var(--light-yellow);
  }

  ${({ $selected }) => $selected && `
    background-color: var(--light-yellow);
    border-color: var(--light-yellow);
  `}
`;

const ErrorMessage = styled(Typography)`
  color: var(--red);
  margin-top: 0.5rem;
`;

const ResponsesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 0;
  border-top: 1px solid var(--border-gray);
`;

const Response = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ResponseIcon = styled.div`
  font-family: Parafina;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: var(--purple);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

const ResponseHeader = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  margin-bottom: 2px;
`;

const ExtraSmallText = styled(Typography)`
  font-size: 0.75rem !important;
`;

const AppealReviewView: React.FC = () => {
  const rejectAppealInputRef = React.useRef<HTMLTextAreaElement>(null);

  const [isRejecting, setIsRejecting] = useState<boolean>(false);
  const [rejectionFormError, setRejectionFormError] = useState<boolean>(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const {
    appealReviewQuery,
    appealReviewId,
    onAppealReviewUpdate,
    updating,
    setUpdating,
    appealReviewIds,
  } = useContext(AppealReviewContext);

  const navigate = useNavigate();

  const acceptAppeal = useMutation({
    mutationFn: async () => {
      const res = await client.post(`appeal-reviews/${appealReviewId}/accept`);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onAppealReviewUpdate();
      navigate('/appeal-reviews');
    },
    onError: () => {
      toast.custom(() => <Toast variant='error'>An error occurred</Toast>);
      setUpdating(false);
    }
  });

  const rejectAppeal = useMutation({
    mutationFn: async () => {
      const body = {
        response: rejectAppealInputRef.current?.value,
      };
      const res = await client.post(`appeal-reviews/${appealReviewId}/reject`, body);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onAppealReviewUpdate();
      navigate('/appeal-reviews');
    },
    onError: () => {
      toast.custom(() => <Toast variant='error'>An error occurred</Toast>);
      setUpdating(false);
    },
  });

  let body;
  if (appealReviewQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (appealReviewQuery.error) {
    body = 'Error, please try again';
  } else if (appealReviewQuery.data) {
    const appealReviewInfo = appealReviewQuery.data.appeal_review;

    const appealReviewTitle = `${appealReviewQuery.data.drug.name} (${appealReviewQuery.data.patient.last_name})`;

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav
            itemId={appealReviewId}
            listIds={appealReviewIds}
            itemBaseUrl={"/appeal-reviews"}
            urlOfTable="/appeal-reviews"
            breadcrumbOrigin='Appeals'
            breadcrumbCurrent={appealReviewTitle}
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
          />

          <ContentOuterWrapper>
            <CenteredContent>
              <Typography styledAs="title" as="h2">{appealReviewTitle}</Typography>

              {/* NOTE: `redrafted` appeal reviews are treated like `rejected` appeal reviews because
                        they were rejected by a user and a justification was given for the rejection. */}
              {appealReviewInfo.status === 'unreviewed' && (
                <FormContainer>
                  <Form $hasError={rejectionFormError}>
                    {updating && (
                      <FormOverlay>
                        <FormOverlayLoadingSpinner />
                      </FormOverlay>
                    )}

                    <FormButtonContainer>
                      <ReviewButtonContainer>
                        <AcceptButton onClick={() => acceptAppeal.mutate()}>
                          Accept
                        </AcceptButton>
                        <RejectButton $selected={isRejecting} onClick={() => {
                          if (isRejecting && !updating) {
                            setIsRejecting(false);
                            setRejectionFormError(false);
                          } else {
                            setIsRejecting(true);
                          }
                        }}>
                          {isRejecting && <img src={warning} />}
                          Request edits
                        </RejectButton>
                      </ReviewButtonContainer>
                    </FormButtonContainer>

                    {isRejecting && <>
                      <ResponseInput
                        ref={rejectAppealInputRef}
                        placeholder="Add a response..."
                        defaultValue=""
                      />
                      <ButtonRow>
                        <SmallButton
                          onClick={() => {
                            if (rejectAppealInputRef.current?.value.length!!) {
                              rejectAppeal.mutate();
                            } else {
                              setRejectionFormError(true);
                            }
                          }}
                          disabled={rejectAppeal.isPending}
                        >
                          Submit
                        </SmallButton>
                      </ButtonRow>
                    </>}
                  </Form>
                  {rejectionFormError && <ErrorMessage styledAs="smallBody" font={500}>You must add a reason to request edits</ErrorMessage>}
                </FormContainer>
              )}

              {(appealReviewInfo.status !== 'unreviewed') && (
                <ResponsesContainer>
                  <Response>
                    {/* TODO: replace with edit icon */}
                    {/* @ts-ignore we're going to replace this anyway */}
                    <ResponseIcon>{appealReviewInfo.reviewed_by.charAt(0)}</ResponseIcon>
                    <ResponseHeader>
                      <Typography styledAs="smallBody" weight={500}>{appealReviewInfo.reviewed_by}</Typography>
                      <ExtraSmallText styledAs="smallBody">{appealReviewInfo.status === 'accepted' ? 'accepted appeal' : 'requested edits'}</ExtraSmallText>
                      <span aria-hidden>·</span>
                      <ExtraSmallText styledAs="smallBody">{moment(appealReviewInfo.reviewed_at).fromNow()}</ExtraSmallText>
                    </ResponseHeader>
                  </Response>

                  {appealReviewInfo.reject_justification && (
                    <Response>
                      {/* This check is just to satisfy typescript. If the appeal has been reviewed, there should be a reviewed_by property */}
                      {appealReviewInfo.reviewed_by && <ResponseIcon>{appealReviewInfo.reviewed_by.charAt(0)}</ResponseIcon>}

                      <div>
                        <ResponseHeader>
                          <Typography styledAs="smallBody" weight={500}>{appealReviewInfo.reviewed_by}</Typography>
                          <span aria-hidden>·</span>
                          <ExtraSmallText styledAs="smallBody">{moment(appealReviewInfo.reviewed_at).fromNow()}</ExtraSmallText>
                        </ResponseHeader>

                        <Typography styledAs="smallBody">{appealReviewInfo.reject_justification}</Typography>
                      </div>
                    </Response>
                  )}
                </ResponsesContainer>
              )}
            </CenteredContent>

            <PDFViewer pdfUrl={url(`/appeal-reviews/${appealReviewId}/document`)} />
          </ContentOuterWrapper>
        </TopNavAndContent>
        <Sidebar $isCollapsed={isSidebarCollapsed}>
          <SidebarTopBar />
          <DrugPanel drug={appealReviewQuery.data.drug} />
          <PatientPanel patient={appealReviewQuery.data.patient} />
          <PrescriberPanel prescriber={appealReviewQuery.data.prescriber} />
        </Sidebar>
      </BodyContainer >
    );
  }

  return body;
};

export default AppealReviewView;
