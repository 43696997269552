import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { client } from '../../api';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import React, { useContext, useState } from 'react';
import { DrugPanel, FilesPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import { FileUploader, UploadedFile } from './Files';
import styled from 'styled-components';
import { taskQueryKey } from '../../queries';
import { TaskContext } from './contexts';
import { Button } from '../Button';
import { FlexRow } from '../Layout';
import moment from 'moment';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import { BodyContainer, TopNavAndContent, ContentOuterWrapper, CenteredContent } from '../tableItemDetailViewComponents/Containers';
import { Form, FormOverlay, FormOverlayLoadingSpinner, ResponseInput, ButtonRow } from '../tableItemDetailViewComponents/FormComponents';

const FormContainer = styled.div`
  margin-top: 4rem;
`;

const TaskInstructions = styled.p`
  font-family: Parafina;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  font-weight: 500;
`;

const TaskSubmitter = styled.div`
  display: inline-block;
`;

const TaskSubmittedAt = styled.div`
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 0.75rem;
  color: var(--dark-gray);
`;

const TaskResponse = styled.div`
  padding: 0.5rem 0 1rem;
  font-weight: 400;
`;

const UploadedFileRow = styled(FlexRow)`
  gap: 0.5rem;
  justify-content: flex-start;
`;

const TaskView: React.FC = () => {
  // Rather than using this ref and a single input, we want to switch
  // to react json schema forms to allow for more dynamic task defintions
  // Until we do that, we are using a less robust but quicker to imoplement
  // solution.
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const queryClient = useQueryClient();
  const { taskQuery, taskId, onTaskUpdate, updating, setUpdating, taskIds } = useContext(TaskContext);
  const navigate = useNavigate();

  const submit = useMutation({
    mutationFn: async () => {
      const body = {
        response: inputRef.current?.value,
      };
      const res = await client.post(`tasks/${taskId}/submit`, body);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onTaskUpdate();
      navigate('/tasks');
    },
  });

  let body;
  if (taskQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (taskQuery.error) {
    body = 'Error, please try again';
  } else if (taskQuery.data) {
    let form;
    switch (taskQuery.data.task.status) {
      case 'queued':
        form = (
          <>
            {taskQuery.data.task.result?.data?.response ? (
              <div>
                {'submitter' in taskQuery.data.task.result && 'submitted_at' in taskQuery.data.task.result ? (
                  <>
                    <TaskSubmitter>{taskQuery.data.task.result['submitter']}</TaskSubmitter>
                    <TaskSubmittedAt>
                      {moment(taskQuery.data.task.result['submitted_at']).format('MMM Do YYYY, h:mm a')}
                    </TaskSubmittedAt>
                  </>
                ) : null}
                <TaskResponse>{taskQuery.data.task.result.data.response}</TaskResponse>
                {taskQuery.data.task.result.files?.map((fileId: string) => (
                  <UploadedFile key={fileId} fileId={fileId} />
                ))}
              </div>
            ) : null}
            <FormContainer>
              <Form>
                {updating && (
                  <FormOverlay>
                    <FormOverlayLoadingSpinner />
                  </FormOverlay>
                )}
                <ResponseInput
                  ref={inputRef}
                  placeholder="Add a response..."
                  defaultValue={taskQuery.data.task.result?.data?.response}
                />
                <UploadedFileRow>
                  {taskQuery.data.task.result?.files?.map((fileId: string) => (
                    <UploadedFile
                      key={fileId}
                      fileId={fileId}
                      onFileDelete={async () => {
                        setUpdating(true);
                        await client.delete(`tasks/${taskId}/file/${fileId}`);
                        await queryClient.invalidateQueries({
                          queryKey: taskQueryKey(taskId),
                          exact: true,
                        });
                        setUpdating(false);
                      }}
                    />
                  ))}
                </UploadedFileRow>
                <ButtonRow>
                  <FileUploader />
                  <Button
                    onClick={() => {
                      submit.mutate();
                    }}
                    disabled={submit.isPending}
                  >
                    {taskQuery.data.task.result?.data?.response ? 'Update' : 'Submit'}
                  </Button>
                </ButtonRow>
              </Form>
            </FormContainer>
          </>
        );
        break;
      case 'completed':
        form = (
          <FormContainer>
            <Form>
              <ResponseInput ref={inputRef} value={taskQuery.data.task.result.data.response} readOnly />
              {taskQuery.data.task.result.files?.map((fileId: string) => <UploadedFile key={fileId} fileId={fileId} />)}
            </Form>
          </FormContainer>
        );
        break;
    }
    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav
            itemId={taskId}
            listIds={taskIds}
            itemBaseUrl={"/tasks"}
            urlOfTable="/tasks"
            breadcrumbOrigin='Tasks'
            breadcrumbCurrent={`${taskQuery.data.drug.name} (${taskQuery.data.patient.last_name})`}
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
          />

          <ContentOuterWrapper>
            <CenteredContent>
              {taskQuery.data.task.type}
              <TaskInstructions>{taskQuery.data.task.extra_context}</TaskInstructions>
              {form}
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>

        {!isSidebarCollapsed && <Sidebar>
          <SidebarTopBar />
          <DrugPanel drug={taskQuery.data.drug} />
          <PatientPanel patient={taskQuery.data.patient} />
          <PrescriberPanel prescriber={taskQuery.data.prescriber} />
          {!!taskQuery.data.task.files?.length && <FilesPanel files={taskQuery.data.task.files} />}
        </Sidebar >}
      </BodyContainer >
    );
  }

  return body;
};

export default TaskView;
